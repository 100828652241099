<div class="container" fxLayout="row" fxLayoutAlign="center center">
  <mat-card fxFlex>
    <div class="iframe-container">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/u2hUs1DFAac"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </mat-card>
</div>
