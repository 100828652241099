// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDju7XhyTgW9c08kV2sax2yqTx8djdlDM8',
    authDomain: 'influencers-a0c05.firebaseapp.com',
    databaseURL: 'https://influencers-a0c05.firebaseio.com',
    projectId: 'influencers-a0c05',
    storageBucket: 'influencers-a0c05.appspot.com',
    messagingSenderId: '857968749357',
    appId: '1:857968749357:web:f5c66ec5ef23c6256241a4',
    measurementId: 'G-H9P70D6PCJ',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
