import { Component, OnInit } from '@angular/core';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  fb = faFacebookF;
  google = faGoogle;

  constructor(private ref: MatDialogRef<LoginComponent>, private login: LoginService) { }

  ngOnInit(): void {
  }

  lgoogle(): void{
    this.login.signInWithGoogle();
    this.ref.close();
  }

  lfb(): void {
    this.login.signInWithFacebook();
    this.ref.close();
  }

}
