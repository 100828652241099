import { Component, OnInit, HostListener } from '@angular/core';
import * as AOS from 'aos';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../dialogs/login/login.component';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  logged: Observable<boolean>;
  constructor(
    private dialog: MatDialog,
    private logg: LoginService,
    private router: Router
  ) {}

  ngOnInit(): void {
    AOS.init();
    this.logged = this.logg.isAuthenticated$;
  }

  login(): void {
    this.logged.subscribe((res) => {
      if (res) {
        this.router.navigate(['/dashboard']);
      } else {
        this.dialog.open(LoginComponent, {
          panelClass: 'login',
        });
      }
    });
  }
}
