import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { LocalUser } from '../models/user';
import {
  switchMap,
  distinctUntilChanged,
  first,
} from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { Funcs } from '../utility/functions';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  currentUser: BehaviorSubject<LocalUser>;
  $logged: Observable<LocalUser>;
  isAuthenticated$: Observable<boolean> = of(false);
  dataFetched = new Subject<boolean>();
  userRef = (id: string): AngularFirestoreDocument<LocalUser> =>
    this.afs.doc(`influencers/${id}`)

  init = (): void => {
    this.currentUser = new BehaviorSubject<LocalUser>(null);
    this.isAuthenticated$ = this.afAuth.authState.pipe(
      switchMap((res) => (res ? of(true) : of(false)))
    );
    this.$logged = this.afAuth.authState.pipe(
      switchMap((user) =>
        user ? this.userRef(user.uid).valueChanges() : of(null)
      )
    );
    this.afAuth.authState.pipe(first()).subscribe((user) => {
      this.userRef(user.uid)
        .get()
        .subscribe((res) => {
          if (!res.exists) {
            this.setUser(user);
          }
        });
    });
    this.$logged.subscribe((user) => {
      this.currentUser.next(user);
      this.dataFetched.next(!!user);
    });
  }

  signInWithGoogle(): Promise<void> {
    this.dataFetched
      .pipe(distinctUntilChanged())
      .subscribe((val) => (val ? this.router.navigate(['/dashboard']) : false));
    const provider = new auth.GoogleAuthProvider();
    return this.afAuth
      .signInWithPopup(provider)
      .then((res) => {
        if (res.additionalUserInfo.isNewUser) {
          this.setUser(res);
        } else {
          this.userRef(res.user.uid)
            .valueChanges()
            .pipe(first())
            .subscribe((user) => {
              if (!user.approved) {
                this.funcs.handleMessages(
                  'This account has not been approved yet.'
                );
                this.logout();
              }
            });
        }
        return res;
      })
      .then(() => {})
      .catch((err) => {
        const message =
          err.message === 'Google is not defined'
            ? 'Some functions are not supported in firefox with tracking protection. Please try other browser or switch off tracking'
            : err.message;
        this.funcs.handleMessages(message);
      });
  }

  signInWithFacebook(): Promise<void> {
    this.dataFetched
      .pipe(distinctUntilChanged())
      .subscribe((val) => (val ? this.router.navigate(['/dashboard']) : false));
    const provider = new auth.FacebookAuthProvider();
    return this.afAuth
      .signInWithPopup(provider)
      .then((res) => {
        if (res.additionalUserInfo.isNewUser) {
          this.setUser(res);
        } else {
          this.userRef(res.user.uid)
            .valueChanges()
            .pipe(first())
            .subscribe((user) => {
              if (!user.approved) {
                this.funcs.handleMessages(
                  'This account has not been approved yet.'
                );
                this.logout();
              }
            });
        }
        return res;
      })
      .then(() => {})
      .catch((err) => {
        const message =
          err.message === 'Facebook is not defined'
            ? 'Some functions are not supported in firefox with tracking protection. Please try other browser or switch off tracking'
            : err.message;
        this.funcs.handleMessages(message);
      });
  }

  setUser(res): void {
          this.userRef(res.user.uid).set(
            {
              uid: res.user.uid,
              name: res.user.displayName,
              personal: {
                gender: res.additionalUserInfo.profile.gender
                  ? res.additionalUserInfo.profile.gender
                  : '',
                phone: res.user.phoneNumber ? res.user.phoneNumber : '',
                photoUrl: res.user.photoURL ? res.user.photoURL : '',
                birthdate: res.additionalUserInfo.profile.birthday
                  ? res.additionalUserInfo.profile.birthday
                  : '',
                email: res.additionalUserInfo.profile.email
                  ? res.additionalUserInfo.profile.email
                  : '',
                student: false,
                profession: '',
                pincode: '',
                address: '',
                city: '',
                state: '',
              },
              instagram: {
                instaid: '',
                nfollowers: null,
              },
              refCode: '',
              firstUpdate: false,
              invited: [],
              college: {
                college: '',
                year: '',
              },
              approved: true,
              antexp: '',
              digitalexp: '',
              poc: null,
              points: 0,
              requests: [],
              completed: []
            } as LocalUser,
            { merge: true }
          ).then(() => this.router.navigate(['/register']));
  }

  logout(): void {
    this.afAuth
      .signOut()
      .then(() => {
        this.router.navigate(['/']);
      })
      .catch((err) => this.funcs.handleMessages(err.message));
  }

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private router: Router,
    private funcs: Funcs
  ) {
    this.init();
  }
}
