<div class="wrapper">
    <h3 data-aos="flip-left">Responsibilities</h3>
    <div class="about-content container" fxLayout.gt-md="row wrap" fxLayout.lt-lg="column" fxLayoutAlign="center center" fxLayoutGap="2vw">
        <mat-card fxFlex="20" *ngFor="let item of responsibilities" data-aos="flip-right">
            <div>
                <mat-card-title class="title">{{ item.title }}</mat-card-title>
                <mat-card-content class="content">{{ item.content }}</mat-card-content>
            </div>
        </mat-card>
    </div>
</div>