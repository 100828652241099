import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsibilites',
  templateUrl: './responsibilites.component.html',
  styleUrls: ['./responsibilites.component.css'],
})
export class ResponsibilitesComponent implements OnInit {

  responsibilities = [
    {
      title: 'Publicize',
      content:
        'Publicize Antaragni from their page.',
    },
    {
      title: 'Advertise',
      content: 'Publicize associated brands and their products.',
    },
    {
      title: 'Coordinate',
      content: 'Get people to register for Antaragni and help out Antaragni.',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
