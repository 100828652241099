import { Component, OnInit } from '@angular/core';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faLinkedin,
  faGoogle,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
})
export class ContactsComponent implements OnInit {
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faInstagram = faInstagram;

  contacts = [
    {
      src: 'https://drive.google.com/uc?export=view&id=1Sqfy3P9Wcp2ejfZNsEWdJ2zwr46Rlhe3',
      name: 'Devyanshu Singh',
      title: 'Organizer, Marketing',
      phone: '+91 9560371233',
      email: 'marketing@antaragni.in',
      instagram: 'dvyn_07',
    },
    {
      src: 'https://drive.google.com/uc?export=view&id=18Ty6pMleMxfzCJip0uOtQxHkDtjJLYOZ',
      name: 'Vishnu Gaur',
      title: 'Organizer, Marketing',
      phone: '+91 7357133855',
      email: 'marketing@antaragni.in',
      instagram: 'vishu._0107',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
