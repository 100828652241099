import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  constructor(private ref: MatDialogRef<NotificationComponent>) { }

  ngOnInit(): void {
  }

  close(): void {
    this.ref.close();
  }

}
