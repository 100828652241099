interface Instagram {
  instaid: string;
  nfollowers: number;
}

interface Personal {
  phone: string;
  photoUrl: string;
  birthdate: string;
  email: string;
  gender: string;
  student: boolean;
  profession: string;
  pincode: string;
  address: string;
  city: string;
  state: string;
}

interface College {
  college: string;
  year: string;
}

export class LocalUser {
  personal: Personal;
  name: string;
  instagram: Instagram;
  refCode: string;
  firstUpdate: boolean;
  invited: Array<string>;
  college: College;
  approved: boolean;
  antexp: string;
  digitalexp: string;
  uid: string;
  poc: string;
  points: number;
  tokenMob: string;
  tokenPC: string;
  requests: Array<string>;
  completed: Array<string>;
}
