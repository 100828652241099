import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class RegisteredUserGuard implements CanActivate {
  constructor(private localUserService: LoginService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.localUserService.$logged.pipe(
      map((result) => {
        if ( !result ) {
          this.router.navigate(['/']);
          return false;
        }
        if ( !result.firstUpdate) {
          this.router.navigate(['/register']);
          return false;
        }
        return true;
      })
    );
  }
}

@Injectable()
export class RegisteredGuard implements CanActivate {
  constructor(private localUserService: LoginService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.localUserService.$logged.pipe(
      map((result) => {
        if (!result) {
          this.router.navigate(['/']);
          return false;
        }
        if (result.firstUpdate) {
          this.router.navigate(['/dashboard/']);
          return false;
        }
        return true;
      })
    );
  }
}
