import { Component, OnInit } from '@angular/core';
import {
  faTrophy,
  faUniversity,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-facts',
  templateUrl: './facts.component.html',
  styleUrls: ['./facts.component.css'],
})
export class FactsComponent implements OnInit {
  facts = [
    { icon: faTrophy, title: 'COMPETITIONS', number: '80+' },
    { icon: faUniversity, title: 'COLLEGES', number: '350+' },
    { icon: faGlobe, title: 'PROFESSIONAL SHOWS', number: '10+' },
  ];
  constructor() {}

  ngOnInit(): void {}
}
