import { Component, OnInit } from '@angular/core';
import {
  faUser,
  faAddressBook,
  faCogs,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-why',
  templateUrl: './why.component.html',
  styleUrls: ['./why.component.css'],
})
export class WhyComponent implements OnInit {
  why = [
    {
      title: 'Be a trend-setter',
      content: 'Be the first to step in the realm of digital marketing',
      icon: faUser,
    },
    {
      title: 'Jump start your career',
      content:
        'Get opportunities to network with celebrities and reputed brands',
      icon: faAddressBook,
    },
    {
      title: 'Enhance your skills',
      content:
        'Design creative campaigns and improve on communication and managerial skills',
      icon: faCogs,
    },
    {
      title: 'Exciting benefits',
      content: 'Many more amazing goodies and vouchers',
      icon: faUser,
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
