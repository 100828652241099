import { Component, OnInit } from '@angular/core';
import { LocalUser } from '../models/user';
import { LoginService } from '../services/login.service';
import { first } from 'rxjs/internal/operators/first';
import { AngularFirestore } from '@angular/fire/firestore';
import { Funcs } from '../utility/functions';
import { Router } from '@angular/router';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  submitted = false;
  code = '';
  user = new LocalUser();
  newuser = new LocalUser();
  n: string;
  count: number;
  constructor(
    private login: LoginService,
    private afs: AngularFirestore,
    private funcs: Funcs,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.login.$logged.pipe(first()).subscribe((res) => {
      this.user = res;
      this.newuser = res;
    });
    this.afs
      .doc('config/refer')
      .valueChanges()
      .pipe(first())
      .subscribe((res: { n: number }) => {
        this.count = res.n;
        this.n = 'IN' + (res.n + 1000).toString();
      });
  }

  onSubmit(): void {
    this.submitted = true;
    this.user.refCode = this.n;
    this.user.firstUpdate = true;
    /* if (this.user.instagram.nfollowers >= 500000000) {
      this.user.approved = true;
    } */
    console.log(this.code);
    if (this.code !== null && this.code.length > 0) {
      this.afs
        .collection('influencers', (ref) =>
          ref.where('refCode', '==', this.code)
        )
        .valueChanges()
        .pipe(first())
        .subscribe((res) => {
          res.forEach((user: LocalUser) => {
            this.afs.doc(`influencers/${user.uid}`).update({
              invited: [...user.invited, this.user.name],
            });
          });
        });
    }
    this.login
      .userRef(this.newuser.uid)
      .update(this.user)
      .then(() => {
        this.afs.doc('config/refer').update({ n: this.count + 1 });
        /* if (this.user.instagram.nfollowers == -5000) {
          this.login.logout();
        } else { */
          this.router.navigate(['/dashboard']);
      })
      .catch((err) => {
        this.submitted = false;
        this.funcs.handleMessages('Some error occured');
      });
  }
}
