<div class="title">Register as Influencer</div>
<div class="content" fxLayout="column" fxLayoutGap="2.5vh">
    <!-- <div class="fb" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1vw" (click)="lfb()">
        <fa-icon [icon]="fb"></fa-icon>
        <div class="content">Sign in With Facebook</div>
    </div> -->
    <div class="google" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1vw" (click)="lgoogle()">
        <fa-icon [icon]="google"></fa-icon>
        <div class="content">Sign in With Google</div>
    </div>
</div>