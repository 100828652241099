<div class="body" fxLayoutAlign="center center" fxLayoutGap="5vh">
    <div class="form" fxLayoutAlign="center center" fxLayout="column" fxFlex="50" fxFlex.xs="90">
        <h1>Register</h1>
        <form #register="ngForm" (ngSubmit)="onSubmit(register)" *ngIf="user.personal" fxLayout="row wrap" fxLayoutGap="1.5vw" fxLayoutAlign="center center">
            <mat-form-field appearance="outline" fxFlex="85">
                <mat-label>Your Name</mat-label>
                <input matInput placeholder="Ex Sandeep Kumar" required minlength="1"  name="Name" [(ngModel)]="user.name" #name="ngModel" required />
                <mat-error *ngIf="name.errors?.required">Name is required</mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline" fxFlex="85">
                <input matInput placeholder="Email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [(ngModel)]="user.personal.email" #email="ngModel" [disabled]="newuser.personal.email && newuser.personal.email.length > 0" required />
                <mat-error *ngIf="email.errors?.required">Email is required</mat-error>
                <mat-error *ngIf="email.errors?.pattern">Please enter a valid email</mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline" fxFlex="85">
                <input matTooltip="10 digits" matInput placeholder="Phone no.*" required minlength="10" maxlength="10" name="phone" [(ngModel)]="user.personal.phone" #mobile_no="ngModel" required />
                <mat-error *ngIf="mobile_no.errors?.required">Mobile number is required</mat-error>
                <mat-error *ngIf="mobile_no.errors?.minlength || mobile_no.errors?.maxlength">Mobile number must be of 10 digits</mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label> Date Of Birth</mat-label>
                <input matInput [matDatepicker]="picker" name="date_of_birth" [(ngModel)]="user.personal.birthdate" #date_of_birth="ngModel" required />
                <mat-error *ngIf="date_of_birth.errors?.required">Date of birth is required</mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            
            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label>Gender</mat-label>
                <mat-select name="gender" [(ngModel)]="user.personal.gender" #gender="ngModel" name="gender" required>
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                    <mat-option value="Other">Other</mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="outline" fxFlex="85">
                <input matInput placeholder="Instagram Handle*" [(ngModel)]="user.instagram.instaid" name="instaid" required />
            </mat-form-field>
            
            <mat-form-field appearance="outline" fxFlex="85">
                <input matInput placeholder="Followers on Instagram*" type="number" [(ngModel)]="user.instagram.nfollowers" name="nfollowers" required />
            </mat-form-field>
            
            <mat-checkbox type="checkbox" name="student" type="primary" [(ngModel)]="user.personal.student">
                Student
            </mat-checkbox>
            <div *ngIf="user.personal.student; else elseBlock" fxLayout.xs="column" fxLayoutAlign="center center">
                <mat-form-field appearance="outline" fxFlex="40" style="margin-right:1vw;">
                    <input matInput placeholder="College/School" type="text" [(ngModel)]="user.college.college" name="college" />
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex="40">
                    <input matInput placeholder="Year/Class" type="text" [(ngModel)]="user.college.year" name="year" />
                </mat-form-field>
            </div>
            <ng-template #elseBlock>
                <mat-form-field appearance="outline" fxFlex="65">
                    <mat-label>Profession</mat-label>
                    <input matInput [(ngModel)]="user.personal.profession" name="profession" />
                </mat-form-field>
            </ng-template>
            
            <mat-form-field appearance="outline" fxFlex="85">
                <input matInput [(ngModel)]="code" [value]="code" placeholder="Provide Referral Code to earn more coins" name="refcode">
            </mat-form-field>
            
            <mat-form-field appearance="outline" id="assoc" fxFlex="85">
                <input matTooltip="Text" matInput placeholder="Any Previous association with Antaragni" [(ngModel)]="user.antexp" name="antexp" />
            </mat-form-field>
            
            <mat-form-field appearance="outline" id="assoc" fxFlex="85">
                <input matInput placeholder="Any previous association with any company in Digital or Network Marketing" [(ngModel)]="user.digitalexp" name="digitalexp" />
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="85">
                <input matInput placeholder="Address*" name="address" [(ngModel)]="user.personal.address" #address="ngModel" required />
                <mat-error *ngIf="address.errors?.required">Address is required</mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline" fxFlex="85">
                <mat-label>City</mat-label>
                <input matInput placeholder="Ex. Kanpur" name="city" [(ngModel)]="user.personal.city" #city="ngModel" required />
                <mat-error *ngIf="city.errors?.required">City is required</mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label>State </mat-label>
                <input matInput placeholder="Ex. Uttarpradesh" value="" name="state" [(ngModel)]="user.personal.state" #state="ngModel" required />
                <mat-error *ngIf="state.errors?.required">State is required</mat-error>
            </mat-form-field>
            
            <mat-form-field appearance="outline" fxFlex="40">
                <mat-label>Pincode </mat-label>
                <input matTooltip="6 digits" required minlength="6" maxlength="6" matInput placeholder="Ex 160036" name="pincode" [(ngModel)]="user.personal.pincode" #pincode="ngModel" required />
                <mat-error *ngIf="pincode.errors?.required">Pincode is required</mat-error>
                <mat-error *ngIf="pincode.errors?.minlength">
                    Pincode must be at least 6 characters long.</mat-error>
                    <mat-error *ngIf="pincode.errors?.maxlength">
                        Pincode must be at max 6 characters long.</mat-error>
                    </mat-form-field>
                    <h3># Personal details are NON-EDITABLE. Please fill carefully.</h3>
                    <button type="submit" class="btn btn-primary mx-auto" [disabled]="!register.valid || submitted">
                        Submit
                    </button>
                </form>
            </div>
        </div>