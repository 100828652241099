<div class="container" fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.lt-lg="column" fxLayoutGap="5vmin">
    <div class="contact-content" fxFlex="100" fxFlex.gt-md="30" *ngFor="let item of contacts" data-aos="flip-right">
        <div class="contact-img-container">
            <img class="contact-img" src="{{ item.src }}" alt="" />
        </div>
        <div class="contact-name first">
            <div class="row">
                <fa-icon [icon]="faPhone" aria-hidden="true"></fa-icon>&nbsp; {{ item.phone }}
            </div>
            <a class="row" [href]="'mailto:' + item.email">
                <fa-icon [icon]="faEnvelope" aria-hidden="true"></fa-icon>&nbsp; {{ item.email }}
            </a>

            <div class="row">
                <a [href]="'https://www.instagram.com/' + item.instagram" target="_blank">
                    <fa-icon [icon]="faInstagram" aria-hidden="true"></fa-icon>
                </a>
            </div>
        </div>
        <div class="contact-name second">{{ item.name }}</div>

        <div class="contact-title">{{ item.title }}</div>
    </div>
</div>