import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { Funcs } from './utility/functions';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { LandingComponent } from './landing/landing.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RegisterComponent } from './register/register.component';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  RegisteredGuard,
  RegisteredUserGuard,
} from './guards/registered.guard';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { AboutComponent } from './landing/about/about.component';
import { WhyComponent } from './landing/why/why.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ResponsibilitesComponent } from './landing/responsibilites/responsibilites.component';
import { IncentivesComponent } from './landing/incentives/incentives.component';
import { FactsComponent } from './landing/facts/facts.component';
import { ContactsComponent } from './landing/contacts/contacts.component';
import { FaqsComponent } from './landing/faqs/faqs.component';
import { LoginComponent } from './dialogs/login/login.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NotificationComponent } from './dialogs/notification/notification.component';
import { BrowserComponent } from './dialogs/browser/browser.component';
import { PlayerComponent } from './landing/player/player.component';
import { YouTubePlayerModule} from "@angular/youtube-player";

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    RegisterComponent,
    AboutComponent,
    WhyComponent,
    ResponsibilitesComponent,
    IncentivesComponent,
    FactsComponent,
    ContactsComponent,
    FaqsComponent,
    LoginComponent,
    NotificationComponent,
    BrowserComponent,
    PlayerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatNativeDateModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    FontAwesomeModule,
    MatDialogModule,
    YouTubePlayerModule
  ],
  providers: [
    Funcs,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    RegisteredGuard,
    RegisteredUserGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
