import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BrowserComponent } from './dialogs/browser/browser.component';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Antaragni Influencers';
  constructor(public router: Router, private dialog: MatDialog) {
    if (navigator.userAgent.includes('wv)')) {
      this.dialog.open(BrowserComponent, {
        panelClass: 'login',
      });
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-166009038-3', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
