<div
  class="main"
  fxLayout="column"
  fxLayoutGap="5vh"
  fxLayoutAlign="center center"
>
  <div class="button-div" data-aos="flip-right">
    <div
      class="button"
      (click)="login()"
      [innerHTML]="(logged | async) ? 'Dashboard' : 'Register'"
    ></div>
  </div>
  <img src="/assets/logo.png" class="logo" />
  <div class="line" data-aos="flip-left"></div>
  <div
    fxLayout="column"
    class="header"
    fxLayoutAlign="center center"
    fxLayoutGap="11vh"
    fxLayoutGap.xs="7vh"
  >
    <p id="heading">Antar<span class="orange">agni</span></p>
    <p>Influencers</p>
  </div>
  <div
    class="links"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="2vw"
  >
    <a
      href="https://www.facebook.com/antaragni.iitk"
      class="link"
      target="_blank"
      >Facebook</a
    >
    <a
      href="https://www.youtube.com/user/antaragniiitkanpur"
      class="link"
      target="_blank"
      >Youtube</a
    >
    <a
      href="https://www.instagram.com/antaragni.iitkanpur/"
      class="link"
      target="_blank"
      >Instagram</a
    >
  </div>
</div>
<div class="container">
  <app-player></app-player>
</div>
<div class="head" data-aos="flip-left">
  <div><span class="orange">About</span> Us</div>
</div>
<div class="container">
  <app-about></app-about>
</div>
<div class="head" data-aos="flip-left"><span class="orange">Why</span> Us</div>
<div class="container">
  <app-why></app-why>
</div>
<app-responsibilites></app-responsibilites>
<div class="head" data-aos="flip-left">Incentives</div>
<div class="container">
  <app-incentives></app-incentives>
</div>
<app-facts></app-facts>
<div class="head" data-aos="flip-left">
  <span class="orange">Contact</span> Us
</div>
<div class="container">
  <app-contacts></app-contacts>
</div>
<app-faqs></app-faqs>
