import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css'],
})
export class FaqsComponent implements OnInit {
  FAQS = [
    {
      question: 'Who can be an Influencer?',
      answer:
        'You must have a public Instagram account and be engaged regularly through content with youth. ',
    },
    {
      question: 'How to become an Influencer?',
      answer:
        'Register yourself by providing the required details.',
    },
    {
      question: 'What are the criteria on which an Influencer is judged?',
      answer:
        'A influencer will be awarded points based on how well he/she executes his/her responsibilities and how much he/she contributes in the execution of Antaragni.',
    },
    {
      question:
        'How can an Influencer get assistance from Team Antaragni when required?',
      answer:
        'You can contact Team Antaragni through any of the contacts provided in "Contact Us" section.',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
