import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
@Injectable()
export class Funcs {
  constructor(private snackbar: MatSnackBar) {}
  handleMessages(message: string): void {
    this.snackbar.open(message, '', {
      duration: 2000,
    });
  }
}
