import { Component, OnInit } from '@angular/core';
import { faTrophy, faCogs, faGift, faHandshake, faLink, faChartLine} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-incentives',
  templateUrl: './incentives.component.html',
  styleUrls: ['./incentives.component.css']
})
export class IncentivesComponent implements OnInit {

  incentives = [
    {
      title: 'Certification',
      content: 'Get certificates from Antaragni, IIT Kanpur',
      icon: faTrophy,
    },
    {
      title: 'Internship Opportunities',
      content:
        'Internship opportunities under renowned influencers and marketing agencies.',
      icon: faCogs,
    },
    {
      title: 'Goodies & Merchandise',
      content:
        'Get a chance to cover Antaragni. Giveaways by Antaragni like coupons, vouchers and goodies.',
      icon: faGift,
    },
    {
      title: 'Association',
      content:
        'Get a chance to associate with famous brands and their campaigns through Antaragni',
      icon: faHandshake,
    },
    {
      title: 'Presentation',
      content: 'Anchor events and shows at Antaragni',
      icon: faLink,
    },
    {
      title: 'Marketing',
      content: 'Courses of Social Media marketing online',
      icon: faChartLine,
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
